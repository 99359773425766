import { authModalPages } from '@/plugins/enums/auth';
import { cookieNames, interval } from '~/plugins/enums/cookie';

export const state = () => ({
  firstLoad: true,
  hasSession: false,
  user: null,
  unreadMessages: 0,
  acceptedPhone: null,
  isOpenedAuthModal: false,
  currentAuthModalPage: null,
  isUserPending: true,
  isSessionPending: true,
  referrerCode: '',
  newReferrerCode: '',
  isNewUser: false,
});

export const getters = {
  getCurrentAuthModalPage: (state) => state.currentAuthModalPage ?? authModalPages.INDEX,
  getAvatarPlaceholder: (state) => {
    if (state.user) {
      const lastNameFirstLetter = state.user.lastName?.slice(0, 1) || '';
      const firstNameFirstLetter = state.user.firstName?.slice(0, 1) || '';

      return lastNameFirstLetter + firstNameFirstLetter;
    }
    return '';
  },
  getUserCompanies: (state) => state.user?.otherAccounts || [],
  currentCompany: (state) => state.user?.currentAccount || {},
  currentCompanyId: (state) => state.user?.currentAccount?.id || 0,
  isAccountOrganization: (state) => state.user?.currentAccount?.is_organization,

  favoritesCount: (state, getters, rootState, rootGetters) => rootGetters['favorites/getFavoritesCount'],
};

export const mutations = {
  SET_HAS_SESSION(state, payload) {
    state.hasSession = payload;
  },
  SET_NEW_USER(state, payload) {
    state.isNewUser = payload;
  },
  SET_IS_OPENED_AUTH_MODAL(state, payload) {
    state.isOpenedAuthModal = payload;
  },
  SET_CURRENT_AUTH_MODAL_PAGE(state, payload) {
    state.currentAuthModalPage = payload;
  },
  SET_USER(state, payload = null) {
    state.user = payload;
  },
  SET_ACCEPTED_PHONE(state, payload = null) {
    state.acceptedPhone = payload;
  },
  SET_USER_PENDING(state, payload) {
    state.isUserPending = payload
  },
  SET_SESSION_PENDING(state, payload) {
    state.isSessionPending = payload
  },
  SET_REFERRER_CODE(state, payload) {
    state.referrerCode = payload
    state.newReferrerCode = ''
  },
  SET_NEW_REFERRER_CODE(state, payload) {
    state.newReferrerCode = payload
  },
  SET_FIRST_LOAD(state, payload) {
    state.firstLoad = payload
  },
  SET_CURRENT_COMPANY(state, payload) {
    state.user.currentAccount = payload;
  },
  SET_USER_COMPANIES(state, payload) {
    state.user.otherAccounts = payload
  },
};

export const actions = {
  setLastPage({ commit }, payload) {
    this.$cookies.set(cookieNames.LAST_PAGE, payload, {
      maxAge: interval.DAY / interval.SECOND, path: '/',
    })
  },
  getLastPage() {
    return this.$cookies.get(cookieNames.LAST_PAGE);
  },
  removeLastPage() {
    return this.$cookies.remove(cookieNames.LAST_PAGE);
  },
  async checkSession({ commit, getters, dispatch }, { force = false, favorites = true }) {
    try {
      commit('SET_SESSION_PENDING', true);

      const { is_login: isLogin } = await this.$api.auth.checkSession({
        params: {
          v: new Date().getTime(),
        },
        forceUpdate: force,
      });
      commit('SET_HAS_SESSION', isLogin);

      if (isLogin && favorites) {
        dispatch('favorites/getFavoritesAll', null, { root: true });
      }

      return isLogin;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      commit('SET_SESSION_PENDING', false);
    }
  },
  loginByPhoneSendSms(_, payload) {
    try {
      return this.$api.auth.loginByPhoneSendSms(payload);
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async loginByPhoneCheckCode({ dispatch }, payload) {
    try {
      await this.$api.auth.loginByPhoneCheckCode(payload);
      await dispatch('checkSession', true);
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async getSocialLink({ commit }, payload) {
    try {
      const data = {
        final_login_url: payload.backUrl,
        driver: payload.driver,
        redirect_social_url: payload.redirectUrl,
      };
      const { url } = await this.$api.auth.getSocialLink(data);
      return url;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async loginBySocial({ dispatch }, payload) {
    try {
      const params = {
        params: payload.query,
      };
      const { url } = await this.$api.auth.loginBySocial(payload.driver, params);
      dispatch('checkSession', true);
      return url;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async logout({ dispatch, commit }) {
    try {
      commit('SET_HAS_SESSION', false);
      commit('SET_USER', null);
      commit('profile/SET_PROFILE', null, { root: true });
      commit('favorites/DELETE_FAVORITES_ALL', null, { root: true });
      dispatch('cart/clearCart', null, { root: true });
      await this.$api.auth.logout();
    } catch (error) {
      commit('SET_HAS_SESSION', false);
      throw error;
    }
  },
  async updateUserNameAvatar({ state, commit }) {
    const {
      email,
      phone,
      avatar,
      first_name: firstName,
      last_name: lastName,
    } = await this.$api.auth.getUserNameAvatar();

    commit('SET_USER', {
      ...state.user,
      avatar,
      firstName,
      lastName,
      email,
      phone,
    });
  },
  async fetchUser({ commit }) {
    let user = null;
    try {
      commit('SET_USER_PENDING', true)
      const {
        can_buy: canBuy,
        referral_code: referralCode,
        referral_partner: referralPartner,
        other_accounts: otherAccounts,
        current_account: currentAccount,
        status,
      } = await this.$api.auth.getUser();

      const {
        email,
        phone,
        avatar,
        first_name: firstName,
        last_name: lastName,
      } = await this.$api.auth.getUserNameAvatar();

      user = {
        canBuy,
        referralCode,
        referralPartner,
        avatar,
        firstName,
        lastName,
        status,
        email,
        phone,
        otherAccounts,
        currentAccount,
      };
      commit('SET_USER', user);
    } catch (error) {
      user = {};
      commit('SET_HAS_SESSION', false);
      commit('SET_USER', user);
      console.error(error);
    } finally {
      commit('SET_USER_PENDING', false)
    }
  },
  async loginByPassword({ dispatch }, payload) {
    try {
      await this.$api.auth.loginByPassword(payload);
      await dispatch('checkSession', true);
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  setUserReferral({ state, commit }, code) {
    try {
      this.$cookies.set(cookieNames.REFERRAL, code, {
        maxAge: interval.DAY / interval.SECOND, path: '/',
      })

      commit('SET_NEW_REFERRER_CODE', code)
    } catch (error) {
      console.log(error)
    }
  },

  async setReferralSession({ state, commit }, code) {
    try {
      await this.$api.auth.setSessionReferralCode(code)
      commit('SET_REFERRER_CODE', code)
    } catch (error) {
      console.log(error)
    }
  },

  setUserCompanies({ commit }, payload) {
    commit('SET_USER_COMPANIES', payload)
  },
  setCurrentCompany({ getters, commit, dispatch }, payload) {
    const companies = getters.getUserCompanies.filter(item => item.id !== payload.id)

    if (getters.currentCompany.id) {
      companies.push(getters.currentCompany)
    }

    dispatch('setUserCompanies', companies)
    commit('SET_CURRENT_COMPANY', payload)
  },
};
