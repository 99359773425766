
import 'vue-select/dist/vue-select.css';
import {getRandomIntInclusive} from '~/plugins/generators';

export default {
  name: 'CustomSelect',
  inheritAttrs: false,
  props: {
    labelOptionName: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    className: {
      type: String,
      default: '',
    },
    isNumeric: {
      type: Boolean,
      default: false,
    },
    styleLight: {
      type: Boolean,
      default: false,
    },
    sizeSm: {
      type: Boolean,
      default: false,
    },
    float: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: '',
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      id: getRandomIntInclusive(1, 100000),
    };
  },
  computed: {
    selectId() {
      return `v-select-${this.id}`;
    },
  },
};
