import menuCodes from '@/plugins/enums/menu';

export const state = () => ({
  isCityConfirmationOpen: false,
  isOpenedSidebar: false,
  menu: [],
  logo: {},
});

export const getters = {
  getMenuHelpBlock: (state) => {
    return state.menu.find(item => item.code === menuCodes.MENU_HELP);
  },
  getMenuMain: (state) => {
    const menuBlock = state.menu.find(item => item.code === menuCodes.MENU_MAIN);
    return menuBlock ? menuBlock.items : [];
  },
  getMenuTop: (state) => {
    const menuBlock = state.menu.find(item => item.code === menuCodes.MENU_TOP);
    return menuBlock ? menuBlock.items : [];
  },
  getMenuHelp: (state, getters) => {
    return getters.getMenuHelpBlock ? getters.getMenuHelpBlock.items : [];
  },
  getMenuHelpAdditional: (state, getters) => {
    return getters.getMenuHelpBlock ? getters.getMenuHelpBlock.additional_info : [];
  },
  getMenuFooter: (state) => {
    const menuBlock = state.menu.find(item => item.code === menuCodes.MENU_FOOTER);
    return menuBlock ? menuBlock.items : [];
  },
};

export const mutations = {
  SET_IS_OPENED_SIDEBAR(state, payload) {
    state.isOpenedSidebar = payload;
  },

  SET_MENU(state, payload) {
    state.menu = payload;
  },

  SET_LOGO(state, payload) {
    state.logo = payload
  },
};

export const actions = {
  setIsOpenedSidebar({ commit }, payload) {
    commit('SET_IS_OPENED_SIDEBAR', payload);
  },

  async nuxtServerInit({ dispatch, commit, state }) {

    const [ logo, menu ] = await Promise.all([
      this.$api.general.getLogo(),
      this.$api.general.getMenu(),
      dispatch('catalog/getAllCategories', null, { root: true }),
      // dispatch('catalog/getFrequentCategories', null, { root: true }),
    ]);

    const { logotype } = logo

    commit('SET_MENU', menu);
    commit('SET_LOGO', {
      pc: logotype?.logotype || '',
      footer: logotype?.logotypeFooter || '',
      mobile: logotype?.mobile || '',
      favicon: logotype?.favicon || '',
    });
  },

  async nuxtClientInit({ dispatch, state, rootState }) {
    await dispatch('cart/getCartItemsCount', null, { root: true });
    dispatch('geolocation/setSelectedCityByIp', null, { root: true });
  },
};
