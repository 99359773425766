
export default {
  name: 'HeaderTopLinks',
  props: {
    links: {
      type: Array,
      required: true,
    },
  },
}
