
import { required, minLength } from 'vuelidate/lib/validators';
import { mapActions, mapMutations, mapState } from 'vuex';
import env from '@/.env.json';

import { TIME_CODE_SMS } from '@/plugins/config';
import { validationMessages } from '@/plugins/validation/messages';
import { authModalPages } from '@/plugins/enums/auth';
import { codeMaskOptions } from '@/plugins/settings';
import { mergeOptions } from '@/plugins/enums/cart';

import InputMask from '@/components/elements/InputMask';
import Alert from '@/components/elements/Alert';
import Timer from '@/components/elements/Timer';
import Button from '@/components/elements/Button';
import CustomCheck from '~/components/elements/CustomCheck.vue';

export default {
  name: 'AuthModalSignUpCode',
  components: {
    CustomCheck,
    InputMask,
    Alert,
    Timer,
    Button,
  },
  props:{
    timer: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      code: null,
      failCode: false,
      codeMaskOptions: { ...codeMaskOptions },
      isDisabledSubmitButton: false,
      timeCodeSMS: TIME_CODE_SMS,
      showErrorAlert: false,
      timerVisible: true,
      termsAgree: null,
      privacyPolicyLink: env.PRIVACY_POLICY,
      privacyPolicyConsentLink: env.PRIVACY_POLICY_CONSENT,
      publicOffer: env.PUBLIC_OFFER,
    };
  },
  computed: {
    ...mapState('geolocation', ['isConfirmedCity']),
    ...mapState('auth', ['acceptedPhone', 'isNewUser']),
    phone() {
      return this.acceptedPhone.replace(/[^\d+]/g, '')
    },
    codeError() {
      const code = this.$v.code;
      let text = '';
      if (code.$dirty) {
        if (!code.required) text = validationMessages.ERROR_REQUIRED;
        if (!code.minLength) text = validationMessages.ERROR_CODE_INPUT;
      }
      if (!this.termsAgree && this.isNewUser) text = validationMessages.ERROR_AGREEMENT;

      const fail = this.$v.failCode;
      if (fail.$dirty && !fail.valid) text = validationMessages.ERROR_CODE;
      return text;
    },
    isDisabledNextButton() {
      return this.isDisabledSubmitButton || this.$v.code.$dirty || (!this.termsAgree && this.isNewUser);
    },
  },
  watch: {
    code() {
      if (this.$v.code.$dirty) this.$v.code.$reset();
      if (this.$v.failCode.$dirty) this.$v.failCode.$reset();
    },
  },
  validations: {
    code: {
      required,
      minLength: minLength(4),
    },
    failCode: {
      valid: (value) => value !== true,
    },
  },
  mounted() {
    this.timerStart();
  },
  beforeDestroy() {
    this.timerStop();
  },
  methods: {
    ...mapMutations('auth', {
      setCurrentAuthModalPage: 'SET_CURRENT_AUTH_MODAL_PAGE',
    }),
    ...mapActions('auth', [
      'loginByPhoneCheckCode',
      'loginByPhoneSendSms',
      'fetchUser',
    ]),
    ...mapActions('cart', [
      'getCartItems',
      'setMergedCart',
    ]),
    ...mapActions('profile', ['loadProfile']),
    async onSubmitCode() {
      // Подтверждение кода
      this.$v.code.$touch();
      if (!this.$v.code.$invalid) {
        try {
          this.isDisabledSubmitButton = true;

          const data = {
            code: this.code,
            phone: this.phone,
          };

          await this.loginByPhoneCheckCode(data);
          this.failCode = false;
        } catch (error) {
          this.failCode = true;
          this.isDisabledSubmitButton = false;
        }

        this.$v.failCode.$touch();

        if (this.failCode) return;

        try {
          const isDifferentCarts = await this.$api.cart.checkCartsDifference();

          if (isDifferentCarts) {
            // вернуть если нужно дать пользователю выбор
            // this.viewMergeCartsModal = true;
            await this.$api.cart.sendMergeCartsOption(mergeOptions.BOTH_CARTS);
            this.setMergedCart(true);
          }

          this.getCartItems();
          this.loadProfile();
          await this.fetchUser();

          if(this.isConfirmedCity) {
            this.closeModal();
          } else {
            this.setCurrentAuthModalPage(authModalPages.SELECT_LOCATION);
          }
        } catch (error) {
          this.showErrorAlert = true;
        }

        this.isDisabledSubmitButton = false;
      }
    },
    async goToSendingSms() {
      try {
        this.isDisabledSubmitButton = true;
        await this.loginByPhoneSendSms({
          phone: this.phone,
        });
        this.failCode = false;
        this.timerStart();
      } catch (error) {
        console.log(error);
        this.failCode = true;
        this.$v.failCode.$touch();
      } finally {
        this.isDisabledSubmitButton = false;
      };
    },
    toStart() {
      this.setCurrentAuthModalPage(authModalPages.INDEX);
    },
    closeModal() {
      this.$emit('closeModal');
    },
    timerStart() {
      this.timerVisible = true;
      this.$nextTick(() => {
        this.$refs.timerCode.start();
      });
    },
    timerStop() {
      if (this.$refs.timerCode) {
        this.$refs.timerCode.stop();
      }
      this.timerVisible = false;
    },
    timerEnd() {
      this.timerVisible = false;
    },
    closeErrorAlert() {
      this.showErrorAlert = false;
    },
  },
};
