export default axios => ({
  async commitCheckoutProductsData(payload) {
    try {
      return await axios
        .$post('/v1/checkout/commit', {
          data: payload,
        })
    } catch (error) {
      throw new Error(`API Request Error (commitCheckoutProductsData): ${error}`);
    }
  },
  async commitCheckoutServicesData(payload) {
    try {
      return await axios
        .$post('/v1/checkout-public-events/commit', {
          data: payload,
        })
    } catch (error) {
      throw new Error(`API Request Error (commitCheckoutServicesData): ${error}`);
    }
  },
  getCheckoutProductsData() {
    return axios.$get('/v1/checkout/data');
  },
  getCheckoutServicesData() {
    return axios.$get('/v1/checkout-public-events/data');
  },
  getCheckoutServiceProfessions() {
    return axios.$get('/v1/directories/professions');
  },
  updateServiceTicket(data) {
    try {
      return axios.$post('/v1/checkout-public-events/tickets', data);
    } catch (error) {
      throw new Error(`API Request Error (updateServiceTicket): ${error}`);
    }
  },
  setReceiveMethod(data) {
    return axios.$post('/v1/checkout/receive-method', data);
  },
  setAddress(data) {
    return axios.$post('/v1/checkout/address', data);
  },
  setPickupPoint(data) {
    return axios.$post('/v1/checkout/pickup-point', data);
  },
  bonus(data) {
    try {
      return axios.$post('/v1/checkout/bonus', data);
    } catch (error) {
      throw new Error(`API Request Error (bonus): ${error}`);
    }
  },
  addCertificate(data) {
    try {
      return axios.$post('/v1/checkout/add-certificate', data)
    } catch (error) {
      throw new Error(`API Request Error (addCertificate): ${error}`);
    }
  },
  addServiceCertificate(data) {
    try {
      return axios.$post('/v1/checkout-public-events/add-certificate', data)
    } catch (error) {
      throw new Error(`API Request Error (addServiceCertificate): ${error}`);
    }
  },
  addCheckoutPromocode(payload) {
    try {
      return axios.$post('/v1/checkout/promo-code', payload);
    } catch (error) {
      throw new Error(`API Request Error (addCheckoutPromocode): ${error}`);
    }
  },
  addCheckoutPublicEventPromocode(payload) {
    try {
      return axios.$post('/v1/checkout-public-events/promo-code', payload);
    } catch (error) {
      throw new Error(`API Request Error (addCheckoutPublicEventPromocode): ${error}`);
    }
  },
  deleteCheckoutPromocode(payload) {
    try {
      return axios.$post('/v1/checkout/promo-code', payload);
    } catch (error) {
      throw new Error(`API Request Error (deleteCheckoutPromocode): ${error}`);
    }
  },
});
