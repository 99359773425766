
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import env from '~/.env.json'
import MenuToggle from '~/layouts/components/Header/MenuToggle';
import HeaderBottomSearch from '~/layouts/components/Header/Bottom/Search';

export default {
  name: 'HeaderBottomMenu',
  components: {
    HeaderBottomSearch,
    MenuToggle,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    isScrollOffset: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      showCatalogMenu: !env.CATALOG_MENU_DISABLED,
    }
  },
  computed: {
    ...mapState('auth', ['hasSession']),
    ...mapGetters({
      menuMain: 'getMenuMain',
      getCartAllItemsCount: 'cart/getCartAllItemsCount',
    }),
    isShowCategories: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    ...mapActions('search', ['setIsOpenedSearch' ]),
    ...mapMutations('auth', {
      setIsOpenedAuthModal: 'SET_IS_OPENED_AUTH_MODAL',
    }),
    isCurrentRoute (url) {
      const isCatalogUrl = url === '/catalog';

      return url === this.$route.path
        || isCatalogUrl && this.$route.path.includes(url) && !this.$route.params.product;
    },
    showSearch() {
      this.setIsOpenedSearch(true);
    },
    openAuthModal() {
      this.setIsOpenedAuthModal(true);
    },
    onCartHandler() {
      this.$router.push({ name: 'cart' });
    },
  },
}
