
export default axios => ({
  getAllReviews(params) {
    try {
      return axios.$get('/v1/reviews', {params});
    } catch (error) {
      throw new Error(`API Request Error (getAllReviews): ${error}`);
    }
  },
  async createReview(data) {
    try {
      return await axios.$post('/v1/reviews', data);
    } catch (error) {
      throw error?.response?.data?.message;
    }
  },
  changeReviewVote(reviewId, opinion) {
    try {
      return axios.$post('/v1/reviews/vote', { review_id: reviewId, opinion });
    } catch (error) {
      throw new Error(`API Request Error (createReview): ${error}`);
    }
  },
});
