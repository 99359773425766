
import { mapState } from 'vuex';

import Header from './components/Header';
import Footer from './components/Footer';
import LocationModal from '@/layouts/components/LocationModal';

import {Schema} from '~/Service/schema/Schema';
import login from '~/mixins/login';
import CookiesAccept from '~/components/elements/CookiesAccept.vue';
import ModalCompanyAdd from '~/components/elements/modals/Company/Add';
import accountChange from '~/mixins/accountChange';
import AuthModal from '~/components/auth/Modal';
import QuickLookModal from '~/components/catalog/quickLook';

export default {
  name: 'MainLayout',
  components: {
    QuickLookModal,
    AuthModal,
    ModalCompanyAdd,
    CookiesAccept,
    Header,
    Footer,
    LocationModal,
  },
  mixins: [login, accountChange],
  head() {
    return {
      link: [{
        rel: 'icon',
        type: 'image/x-icon',
        href: this.logo.favicon.url,
      }],
      script: [
        Schema.organization(this.logo.pc.url),
      ],
    }
  },
  computed: {
    ...mapState('auth', ['isOpenedAuthModal']),
    ...mapState('quickLook', ['modalState']),
    ...mapState('search', ['isOpenedSearch']),
    ...mapState({logo: 'logo'}),
    ...mapState('geolocation', ['visibleLocationModal']),
    ...mapState('profile', ['modalAddCompany']),
    mqIsMobile() {
      return this.$mq === 'mobileXs' || this.$mq === 'mobile' || this.$mq === 'tablet' || this.$mq === 'laptop';
    },
  },
}
