
import {mapGetters} from 'vuex';

export default {
  name: 'InputTel',
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    mode: {
      type: String,
      default: 'international',
    },
    dropdownOptions: {
      type: Object,
      default: () => {},
    },
    inputOptions: {
      type: Object,
      default: () => {},
    },
    error: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Введите телефон',
    },
  },
  data() {
    const defaultDropdown = {
      showDialCodeInList: true,
      showDialCodeInSelection: false,
      showSearchBox: true,
      showFlags: true,
      ignoredCountries: [],
    }

    const defaultInput = {
      placeholder: this.placeholder,
      enabledCountryCode: true,
      showDialCode: true,
    }

    return {
      optionsDropdown: { ...defaultDropdown },
      defaultDropdown: { ...defaultDropdown },

      optionsInput: { ...defaultInput },
      defaultInput: { ...defaultInput },
    }
  },
  computed: {
    ...mapGetters({
      getAddresses: 'checkout/getAddresses',
      getCountriesList: 'geolocation/getCountriesList',
      getSelectedCountry: 'geolocation/getSelectedCountry',
    }),
    inputValue: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('input', newValue || '')
      },
    },
    onlyCountries() {
      return this.getCountriesList.map(item => item.value)
    },
    defaultCountry() {
      return this.getSelectedCountry.value
    },
  },
  watch: {
    placeholder() {
      this.optionsInput.placeholder = this.placeholder
    },
  },
  methods: {
    onInput(value) {
      if (value == null) {
        return (this.inputValue = '')
      }

      const lastLen = this.inputValue.replace(/\D/g, '').length
      const currentLen = value.replace(/\D/g, '').length

      if (lastLen < currentLen && currentLen > 10) {
        return false
      }
      this.inputValue = value
    },
    onInputUpdate({ isValid, formatInternational, formattedNumber }) {
      this.$emit('onValidate', { isValid, international: formatInternational, number: formattedNumber })
    },
  },
}
