export default axios => ({
  articleDetail(code) {
    try {
      return axios.$get('/v1/cms/blog-articles/detail', {
        params: { code },
      });
    } catch (error) {
      throw new Error(`API Request Error (articleDetail): ${error}`);
    }
  },
  // eslint-disable-next-line camelcase
  articleList(query) {
    try {
      return axios.$get('/v1/cms/blog-articles/list', {
        params: query,
      });
    } catch (error) {
      throw new Error(`API Request Error (articleList): ${error}`);
    }
  },
  // eslint-disable-next-line camelcase
  authorsList({ search_string = ''}) {
    try {
      return axios.$get('/v1/cms/experts/list', {
        params: { search_string },
      });
    } catch (error) {
      throw new Error(`API Request Error (authorsList): ${error}`);
    }
  },
  articleReaction(id, reaction) {
    try {
      return axios.$post('/v1/cms/blog-articles/make-reaction', {
        articleId: id, reactionId: reaction,
      });
    } catch (error) {
      throw new Error(`API Request Error (articleReaction): ${error}`);
    }
  },
})
