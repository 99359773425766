
import {mapState} from 'vuex';
import MenuToggle from '~/layouts/components/Header/MenuToggle.vue';
import Logo from '~/components/elements/Logo.vue';
import HeaderMainActions from '~/layouts/components/Header/Main/Actions/index.vue';
import HeaderMainSearch from '~/layouts/components/Header/Main/Search';

export default {
  name: 'HeaderMain',
  components: {
    HeaderMainSearch,
    HeaderMainActions,
    Logo,
    MenuToggle,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    openedMobileMenu: {
      type: Boolean,
      required: true,
    },
    isScrollOffset: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapState({logo: 'logo'}),
    isShowCategories: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    isOpenedMobileMenu: {
      get() {
        return this.openedMobileMenu
      },
      set(value) {
        this.$emit('changeMobileMenu', value)
      },
    },
  },
}
