import {cookieNames, interval} from '~/plugins/enums/cookie';

export default async function checkProfile({ route, store, redirect, $cookies }) {
  if (!store.state.auth.hasSession) {
    $cookies.set(cookieNames.LAST_PAGE, route.path, {
      maxAge: interval.DAY, path: '/',
    })
    redirect('/auth');
  } else if (!store.state.profile.profile) {
    await store.dispatch('profile/loadProfile');
  }
}
