export const state = () => ({
  favoritesAll: [],
});

export const getters = {
  getFavoritesCount: (state) => {
    return state.favoritesAll.length;
  },
  productInFavorites: (state) => (id) => {
    return state.favoritesAll?.includes(+id) ?? [];
  },
};

export const mutations = {
  DELETE_FAVORITES_ALL(state) {
    state.favoritesAll = [];
  },
  SET_FAVORITES_ALL(state, payload) {
    state.favoritesAll = payload;
  },
  ADD_FAVORITES_ITEM(state, productId) {
    state.favoritesAll.push(productId);
  },
  DELETE_FAVORITES_ITEM(state, productId) {
    const id = state.favoritesAll.indexOf(productId);
    if (id !== -1) state.favoritesAll.splice(id, 1);
  },
};

export const actions = {
  async getFavoritesAll({ commit }) {
    const result = await this.$api.favorites.getFavoritesAll();
    commit('SET_FAVORITES_ALL', Object.values(result));
  },
  async deleteFavoritesAll({ commit }) {
    await this.$api.favorites.deleteFavoritesAll();
    commit('DELETE_FAVORITES_ALL');
  },
  async addFavoritesItem({ commit }, id) {
    await this.$api.favorites.addFavoritesItem(id);
    commit('ADD_FAVORITES_ITEM', +id);
  },
  async deleteFavoritesItem({ commit }, id) {
    await this.$api.favorites.deleteFavoritesItem(id);
    commit('DELETE_FAVORITES_ITEM', +id);
  },
};
